<template>
  <div
    class="container-vehicule d-flex flex-stack"
    v-if="$hasFonction(user, 'Activer caractéristique véhicule')"
  >
    <!-- Block marque start -->
    <div :class="Class()">
      <div :hidden="hiddenLoaderMark" id="" class="loader text-center">
        <b-icon-circle-fill
          animation="throb"
          font-scale="4"
        ></b-icon-circle-fill>
      </div>

      <div>
        <div v-show="addMark">
          <app-collapse>
            <app-collapse-item
              isVisible
              title="Ajouter une marque"
              v-if="$hasFonction(user, 'Ajouter marques')"
            >
              <!-- form start -->
              <validation-observer
                ref="addMarqueForm"
                class="d-flex justify-content-center"
              >
                <b-form class="mt-1 col" @submit.prevent>
                  <b-form-group>
                    <validation-provider
                      #default="{ errors }"
                      name="name"
                      rules="required"
                    >
                      <label for="name">Marque</label>
                      <b-form-input
                        id="name"
                        v-model="defaultMarqueForm.name"
                        :state="errors.length > 0 ? false : null"
                        type="text"
                        placeholder="Marque"
                        @click="resetInputNameMarque"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                      <small class="text-danger">{{ errorsNameMarque }}</small>
                    </validation-provider>
                  </b-form-group>
                  <!-- submit button start -->
                  <div class="mt-3">
                    <b-button
                      class="mr-2"
                      variant="outline-secondary"
                      type="reset"
                      @click="resetInputMarque"
                    >
                      Annuler
                    </b-button>
                    <b-button
                      v-if="etatButtonMark"
                      variant="primary"
                      type="submit"
                      :disabled="isOperatingMarque"
                      @click.prevent="addMarque"
                    >
                      Enregistrer
                    </b-button>

                    <b-button v-else variant="primary">
                      <b-icon-arrow-clockwise
                        animation="spin"
                        font-scale="1"
                      ></b-icon-arrow-clockwise>
                      En cours
                    </b-button>
                  </div>
                  <!-- submit button end -->
                </b-form>
              </validation-observer>
              <!-- form end -->
            </app-collapse-item>
          </app-collapse>
        </div>

        <div :hidden="hiddenMark">
          <app-collapse>
            <app-collapse-item
              ref="collapseMark"
              title="Ajouter une marque"
              v-if="$hasFonction(user, 'Ajouter marques')"
            >
              <!-- form start -->
              <validation-observer
                ref="addMarqueForm"
                class="d-flex justify-content-center"
              >
                <b-form class="mt-1 col" @submit.prevent>
                  <b-form-group>
                    <validation-provider
                      #default="{ errors }"
                      name="name"
                      rules="required"
                    >
                      <label for="name">Marque</label>
                      <b-form-input
                        id="name"
                        v-model="defaultMarqueForm.name"
                        :state="errors.length > 0 ? false : null"
                        type="text"
                        placeholder="Marque"
                        @click="resetInputNameMarque"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                      <small class="text-danger">{{ errorsNameMarque }}</small>
                    </validation-provider>
                  </b-form-group>
                  <!-- submit button start -->
                  <div class="mt-3">
                    <b-button
                      class="mr-2"
                      variant="outline-secondary"
                      type="reset"
                      @click="resetInputMarque"
                    >
                      Annuler
                    </b-button>

                    <b-button
                      v-if="etatButtonMark"
                      variant="primary"
                      type="submit"
                      :disabled="isOperatingMarque"
                      @click.prevent="addMarque"
                    >
                      Enregistrer
                    </b-button>

                    <b-button v-else variant="primary">
                      <b-icon-arrow-clockwise
                        animation="spin"
                        font-scale="1"
                      ></b-icon-arrow-clockwise>
                      En cours
                    </b-button>
                  </div>
                  <!-- submit button end -->
                </b-form>
              </validation-observer>
              <!-- form end -->
            </app-collapse-item>
          </app-collapse>

          <b-card no-body title="" class="mt-1">
            <div class="container-search-btn flex-stack my-2 mx-2">
              <!-- search start -->
              <div class="container-search mr-1">
                <b-form-input
                  id="filter-inputMarque"
                  v-model="filterMarque"
                  type="search"
                  placeholder="Recherche"
                >
                </b-form-input>
              </div>
              <!-- search end -->

              <!-- bouton export start -->
			  <div>
				<b-button
					v-if="$hasFonction(user, 'Exporter marques')"
					variant="outline-primary"
					size="sm"
					class=""
					@click="ExportFomatExcelMarque"
				>
					<b-icon-file-earmark-arrow-down-fill></b-icon-file-earmark-arrow-down-fill>
					Exporter
				</b-button>

        <b-button
					v-if="$hasFonction(user, 'Exporter marques')"
					variant="outline-primary"
					size="sm"
					class="mt-0"
					@click="ExportAllToFomatExcelMarque"
				>
					<b-icon-file-earmark-arrow-down-fill></b-icon-file-earmark-arrow-down-fill>
					Tout Exporter
				</b-button>

			  </div>
              <!-- bouton export end -->
            </div>

            <!-- table start -->
            <b-table
              v-model="ExcelMarques"
              id="my-table"
              responsive="sm"
              :fields="headersMarques"
              :items="marques"
              :per-page="perPageMarque"
              :current-page="currentPageMarque"
              :filter="filterMarque"
              show-empty
              class="border-bottom"
            >
              <!-- iconAction start -->
              <template #cell(actions)="data">
                <div class="row">
                  <div>
                    <b-button
                      :id="'button-3' + data.item.id"
                      v-if="$hasFonction(user, 'Modifier marques')"
                      size="sm"
                      variant="flat-primary"
                      class="btn-icon"
                      @click="editMarque(data.item)"
                    >
                      <feather-icon icon="Edit2Icon" />
                    </b-button>
                    <!-- <b-tooltip
                      :target="'button-3' + data.item.id"
                      title="Editer"
                      variant="dark"
                    ></b-tooltip> -->
                  </div>
                  <div>
                    <b-button
                      :id="'button-4' + data.item.id"
                      v-if="$hasFonction(user, 'Supprimer marques')"
                      size="sm"
                      variant="flat-danger"
                      @click="deleteMarque(data.item)"
                    >
                      <feather-icon icon="TrashIcon" />
                    </b-button>
                    <!-- <b-tooltip
                      :target="'button-4' + data.item.id"
                      title="Supprimer"
                      variant="dark"
                    ></b-tooltip> -->
                  </div>
                </div>
              </template>
              <!-- iconAction end -->

              <template #empty>
                <div class="text-center">
                  <span class="text-nowrap">Aucune donnée existante</span>
                </div>
              </template>
              <template #emptyfiltered>
                <div class="text-center">
                  <span class="text-nowrap"
                    >L'élément recherché n'est pas dans la liste</span
                  >
                </div>
              </template>
            </b-table>
            <!-- table end -->

            <div class="my-2 mx-2 row">
              <!-- per-page end -->
              <div class="col">
                <small>
                  <span class="text-nowrap mr-1"> Montré de 1 à </span>
                  <b-form-select
                    id="per-page-select"
                    v-model="perPageMarque"
                    :options="pageOptionsMarque"
                    style="width: 70px; height: 30px"
                  ></b-form-select>
                  <span class="text-nowrap ml-1">
                    de {{ totalRowsMarque }} entrées
                  </span>
                </small>
              </div>
              <!-- per-page end -->

              <!-- pagination start -->
              <b-pagination
                v-model="currentPageMarque"
                :total-rows="totalRowsMarque"
                :per-page="perPageMarque"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                aria-controls="my-table"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
              <!-- pagination end -->
            </div>
          </b-card>

          <!-- modal edit start  -->
          <b-modal
            id="modal-editMarque"
            v-model="modalEditMarque"
            :ok-disabled="isOperatingMarque"
            title="Modifier les informations"
            @hidden="resetMarqueFormModal"
            hide-footer
            centered
          >
            <!-- form start -->
            <b-form
              ref="editMarqueForm"
              @submit.stop.prevent="handleSubmitMarque"
            >
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="name"
                  rules="required"
                >
                  <label for="name">Marque</label>
                  <b-form-input
                    id="name"
                    placeholder="Marque"
                    type="text"
                    v-model="editedMarqueForm.name"
                    :state="errors.length > 0 ? false : null"
                    @click="resetInputNameMarqueModal"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small class="text-danger">{{
                    errors[0] ? "" : errorsNameMarqueModal
                  }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group class="mb-0">
                <b-button
                  variant="outline-secondary"
                  type="reset"
                  class="mt-2 mr-1"
                  @click="modalEditMarque = false"
                >
                  Annuler
                </b-button>

                <b-button
                  v-if="etatButtonEditMarque"
                  variant="primary"
                  class="mt-2"
                  :disabled="isOperatingMarque"
                  @click="handleOkMarque"
                >
                  Enregistrer
                </b-button>

                <b-button v-else variant="primary" class="mt-2">
                  <b-icon-arrow-clockwise
                    animation="spin"
                    font-scale="1"
                  ></b-icon-arrow-clockwise>
                  En cours
                </b-button>
              </b-form-group>
            </b-form>
            <!-- form end -->
          </b-modal>
          <!-- modal edit end-->
        </div>

        <div :hidden="hiddenEmptyMark" class="text-center">
          <empty-list></empty-list>
          <b-button variant="primary" size="sm" @click="etatAddMark">
            <b-icon-plus></b-icon-plus>
            AJOUTER MARQUE
          </b-button>
        </div>
      </div>
    </div>
    <!-- Block marque end -->

    <!-- Block Couleur start -->
    <div class="container-btn ml-2">
      <div :hidden="hiddenLoaderColor" id="" class="loader text-center">
        <b-icon-circle-fill
          animation="throb"
          font-scale="4"
        ></b-icon-circle-fill>
      </div>

      <div>
        <div v-show="addColor">
          <app-collapse>
            <app-collapse-item
              isVisible
              title="Ajouter une couleur"
              v-if="$hasFonction(user, 'Ajouter couleurs')"
            >
              <!-- form start -->
              <validation-observer
                ref="addCouleurForm"
                class="d-flex justify-content-center"
              >
                <b-form class="mt-1 col" @submit.prevent>
                  <b-form-group>
                    <validation-provider
                      #default="{ errors }"
                      name="name"
                      rules="required"
                    >
                      <label for="name">Couleur</label>
                      <b-form-input
                        id="name"
                        v-model="defaultCouleurForm.name"
                        :state="errors.length > 0 ? false : null"
                        type="text"
                        placeholder="Couleur"
                        @click="resetInputNameCouleur"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                      <small class="text-danger">{{ errorsNameCouleur }}</small>
                    </validation-provider>
                  </b-form-group>
                  <!-- submit button start -->
                  <div class="mt-3">
                    <b-button
                      class="mr-2"
                      variant="outline-secondary"
                      type="reset"
                      @click="resetInputCouleur"
                    >
                      Annuler
                    </b-button>

                    <b-button
                      v-if="etatButtonColor"
                      variant="primary"
                      type="submit"
                      :disabled="isOperatingCouleur"
                      @click.prevent="addCouleur"
                    >
                      Enregistrer
                    </b-button>

                    <b-button v-else variant="primary">
                      <b-icon-arrow-clockwise
                        animation="spin"
                        font-scale="1"
                      ></b-icon-arrow-clockwise>
                      En cours
                    </b-button>
                  </div>
                  <!-- submit button end -->
                </b-form>
              </validation-observer>
              <!-- form end -->
            </app-collapse-item>
          </app-collapse>
        </div>

        <div :hidden="hiddenColor">
          <app-collapse>
            <app-collapse-item
              ref="collapseColor"
              title="Ajouter une couleur"
              v-if="$hasFonction(user, 'Ajouter couleurs')"
            >
              <!-- form start -->
              <validation-observer
                ref="addCouleurForm"
                class="d-flex justify-content-center"
              >
                <b-form class="mt-1 col" @submit.prevent>
                  <b-form-group>
                    <validation-provider
                      #default="{ errors }"
                      name="name"
                      rules="required"
                    >
                      <label for="name">Couleur</label>
                      <b-form-input
                        id="name"
                        v-model="defaultCouleurForm.name"
                        :state="errors.length > 0 ? false : null"
                        type="text"
                        placeholder="Couleur"
                        @click="resetInputNameCouleur"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                      <small class="text-danger">{{ errorsNameCouleur }}</small>
                    </validation-provider>
                  </b-form-group>
                  <!-- submit button start -->
                  <div class="mt-3">
                    <b-button
                      class="mr-2"
                      variant="outline-secondary"
                      type="reset"
                      @click="resetInputCouleur"
                    >
                      Annuler
                    </b-button>

                    <b-button
                      v-if="etatButtonColor"
                      variant="primary"
                      type="submit"
                      :disabled="isOperatingCouleur"
                      @click.prevent="addCouleur"
                    >
                      Enregistrer
                    </b-button>

                    <b-button v-else variant="primary">
                      <b-icon-arrow-clockwise
                        animation="spin"
                        font-scale="1"
                      ></b-icon-arrow-clockwise>
                      En cours
                    </b-button>
                  </div>
                  <!-- submit button end -->
                </b-form>
              </validation-observer>
              <!-- form end -->
            </app-collapse-item>
          </app-collapse>

          <b-card no-body title="" class="mt-1">
            <div class="container-search-btn flex-stack my-2 mx-2">
              <!-- search start -->
              <div class="container-search mr-1">
                <b-form-input
                  id="filter-inputCouleur"
                  v-model="filterCouleur"
                  type="search"
                  placeholder="Recherche"
                >
                </b-form-input>
              </div>
              <!-- search end -->

              <!-- bouton export start -->
			  <div>
				<b-button
					v-if="$hasFonction(user, 'Exporter couleurs')"
					variant="outline-primary"
					size="sm"
					class=""
					@click="ExportFomatExcelCouleur"
				>
					<b-icon-file-earmark-arrow-down-fill></b-icon-file-earmark-arrow-down-fill>
					Exporter
				</b-button>

        <b-button
					v-if="$hasFonction(user, 'Exporter couleurs')"
					variant="outline-primary"
					size="sm"
					class="mt-0"
					@click="ExportAllToFomatExcelCouleur"
				>
					<b-icon-file-earmark-arrow-down-fill></b-icon-file-earmark-arrow-down-fill>
					Tout Exporter
				</b-button>

			  </div>
              <!-- bouton export end -->
            </div>

            <!-- table start -->
            <b-table
              v-model="ExcelCouleurs"
              id="my-table"
              responsive="sm"
              :fields="headersCouleurs"
              :items="couleurs"
              :per-page="perPageCouleur"
              :current-page="currentPageCouleur"
              :filter="filterCouleur"
              show-empty
              class="border-bottom"
            >
              <!-- iconAction start -->
              <template #cell(actions)="data">
                <div class="row">
                  <div>
                    <b-button
                      :id="'button-1' + data.item.id"
                      v-if="$hasFonction(user, 'Modifier couleurs')"
                      size="sm"
                      variant="flat-primary"
                      class="btn-icon"
                      @click="editCouleur(data.item)"
                    >
                      <feather-icon icon="Edit2Icon" />
                    </b-button>
                    <!-- <b-tooltip
                      :target="'button-1' + data.item.id"
                      title="Editer"
                      variant="dark"
                    ></b-tooltip> -->
                  </div>
                  <div>
                    <b-button
                      :id="'button-2' + data.item.id"
                      v-if="$hasFonction(user, 'Supprimer couleurs')"
                      size="sm"
                      variant="flat-danger"
                      @click="deleteCouleur(data.item)"
                    >
                      <feather-icon icon="TrashIcon" />
                    </b-button>
                    <!-- <b-tooltip
                      :target="'button-2' + data.item.id"
                      title="Supprimer"
                      variant="dark"
                    ></b-tooltip> -->
                  </div>
                </div>
              </template>
              <!-- iconAction end -->

              <template #empty>
                <div class="text-center">
                  <span class="text-nowrap">Aucune donnée existante</span>
                </div>
              </template>
              <template #emptyfiltered>
                <div class="text-center">
                  <span class="text-nowrap"
                    >L'élément recherché n'est pas dans la liste</span
                  >
                </div>
              </template>
            </b-table>
            <!-- table end -->

            <div class="my-2 mx-2 row">
              <!-- per-page end -->
              <div class="col">
                <small>
                  <span class="text-nowrap mr-1"> Montré de 1 à </span>
                  <b-form-select
                    id="per-page-select"
                    v-model="perPageCouleur"
                    :options="pageOptionsCouleur"
                    style="width: 70px; height: 30px"
                  ></b-form-select>
                  <span class="text-nowrap ml-1">
                    de {{ totalRowsCouleur }} entrées
                  </span>
                </small>
              </div>
              <!-- per-page end -->

              <!-- pagination start -->
              <b-pagination
                v-model="currentPageCouleur"
                :total-rows="totalRowsCouleur"
                :per-page="perPageCouleur"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                aria-controls="my-table"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
              <!-- pagination end -->
            </div>
          </b-card>

          <!-- modal edit start  -->
          <b-modal
            id="modal-editCouleur"
            v-model="modalEditCouleur"
            :ok-disabled="isOperatingCouleur"
            title="Modifier les informations"
            @hidden="resetCouleurFormModal"
            hide-footer
            centered
          >
            <!-- form start -->
            <b-form
              ref="editCouleurForm"
              @submit.stop.prevent="handleSubmitCouleur"
            >
              <b-form-group
                invalid-feedback="Ce champ est requis"
                :state="nameState"
              >
                <validation-provider
                  #default="{ errors }"
                  name="name"
                  rules="required"
                >
                  <label for="name">Couleur</label>
                  <b-form-input
                    id="name"
                    placeholder="Couleur"
                    type="text"
                    v-model="editedCouleurForm.name"
                    :state="errors.length > 0 ? false : null"
                    @click="resetInputNameCouleurModal"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small class="text-danger">{{
                    errors[0] ? "" : errorsNameCouleurModal
                  }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group class="mb-0">
                <b-button
                  variant="outline-secondary"
                  type="reset"
                  class="mt-2 mr-1"
                  @click="modalEditCouleur = false"
                >
                  Annuler
                </b-button>

                <b-button
                  v-if="etatButtonEditCouleur"
                  variant="primary"
                  class="mt-2"
                  :disabled="isOperatingCouleur"
                  @click="handleOkCouleur"
                >
                  Enregistrer
                </b-button>

                <b-button v-else variant="primary" class="mt-2">
                  <b-icon-arrow-clockwise
                    animation="spin"
                    font-scale="1"
                  ></b-icon-arrow-clockwise>
                  En cours
                </b-button>
              </b-form-group>
            </b-form>
            <!-- form end -->
          </b-modal>
          <!-- modal edit end-->
        </div>

        <div :hidden="hiddenEmptyColor" class="text-center">
          <empty-list></empty-list>
          <b-button variant="primary" size="sm" @click="etatAddColor">
            <b-icon-plus></b-icon-plus>
            AJOUTER COULEUR
          </b-button>
        </div>
      </div>
    </div>
    <!-- Block Couleur end -->
  </div>
</template>

<script>
import { mapState } from "vuex";
import EmptyList from "@core/components/empty-list/EmptyList.vue";
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import { json2excel, excel2json } from "js2excel";
import downloadExcel from "json2excel-js";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, integer, alpha, alphaDash } from "@validations";
import {
  BCard,
  BTable,
  BCardText,
  BAlert,
  BModal,
  BPagination,
  BButton,
  BIconPencilFill,
  BIconTrashFill,
  BIconArrowClockwise,
  BIconPlus,
  BIconCircleFill,
  BIconFileEarmarkArrowDownFill,
  BForm,
  BRow,
  BCol,
  BTooltip,
  BFormGroup,
  BFormSelect,
  BFormInput,
  BCollapse,
  BFormInvalidFeedback,
} from "bootstrap-vue";

export default {
  components: {
    BCard,
    BTable,
    BModal,
    BCardText,
    BAlert,
    BCardCode,
    BPagination,
    BButton,
    EmptyList,
    BIconArrowClockwise,
    BIconPlus,
    BIconPencilFill,
    BIconTrashFill,
    BIconCircleFill,
    BIconFileEarmarkArrowDownFill,
    BForm,
    BRow,
    BCol,
    BTooltip,
    BFormGroup,
    BFormSelect,
    BFormInput,
    BCollapse,
    BFormInvalidFeedback,
    AppCollapse,
    AppCollapseItem,
    ValidationProvider,
    ValidationObserver,
    json2excel,
    excel2json,
    downloadExcel,
  },
  data() {
    return {
      // Marque start
      hiddenMark: true,
      hiddenEmptyMark: true,
      hiddenLoaderMark: false,
      addMark: false,
      etatButtonMark: true,
      etatButtonEditMarque: true,
      etatButtonEditCouleur: true,
      isOperatingMarque: false,
      required,
      integer,
      alpha,
      alphaDash,
      nameState: null,
      errorsNameMarque: null,
      errorsNameMarqueModal: null,
      modalEditMarque: false,
      perPageMarque: 5,
      currentPageMarque: 1,
      filterMarque: null,
      pageOptionsMarque: [5, 10, 25, 50, 100],
      headersMarques: [
        {
          key: "name",
          label: "Nom",
          sortable: true,
          class: "w-25",
        },
        {
          key: "actions",
          label: "Actions",
          sortable: false,
          class: "w-25",
        },
      ],
      marques: [],
      ExcelMarques: null,
      editedMarqueForm: {
        name: "",
      },
      defaultMarqueForm: {
        name: "",
      },
      // Marque end

      // Couleur start
      hiddenColor: true,
      hiddenEmptyColor: true,
      hiddenLoaderColor: false,
      etatButtonColor: true,
      addColor: false,
      isOperatingCouleur: false,
      errorsNameCouleur: null,
      errorsNameCouleurModal: null,
      modalEditCouleur: false,
      perPageCouleur: 5,
      currentPageCouleur: 1,
      filterCouleur: null,
      pageOptionsCouleur: [5, 25, 50, 100],
      headersCouleurs: [
        // {
        // 	key: '#',
        // 	label: '',
        // 	sortable: false,
        // },
        {
          key: "name",
          label: "Nom",
          sortable: true,
          class: "w-25",
        },
        {
          key: "actions",
          label: "Actions",
          sortable: false,
          class: "w-25",
        },
      ],
      couleurs: [],
      ExcelCouleurs: null,
      editedCouleurForm: {
        name: "",
      },
      defaultCouleurForm: {
        name: "",
      },
      // Couleur end
    };
  },
  mounted() {},
  computed: {
    ...mapState({
      user: (state) => state.user.auth,
    }),
    totalRowsMarque() {
      // Set the initial number of items
      return this.marques.length;
    },
    totalRowsCouleur() {
      // Set the initial number of items
      return this.marques.length;
    },
  },
  created() {
    this.getMarques();
    this.getCouleurs();
  },
  methods: {
    etatAddMark() {
      this.addMark = true;
      this.hiddenEmptyMark = true;
    },
    etatAddColor() {
      this.addColor = true;
      this.hiddenEmptyColor = true;
    },
    // Marque start
    resetMarqueForm() {
      this.defaultMarqueForm.name = "";
    },
    resetMarqueFormModal() {
      this.editedMarqueForm.name = "";
      this.nameState = null;
      this.resetInputMarqueModal();
    },
    resetInputMarque() {
      this.resetInputNameMarque();
    },
    resetInputNameMarque() {
      if (this.errorsNameMarque) {
        this.errorsNameMarque = null;
      }
    },
    resetInputMarqueModal() {
      this.resetInputNameMarqueModal();
    },
    resetInputNameMarqueModal() {
      if (this.errorsNameMarqueModal) {
        this.errorsNameMarqueModal = null;
      }
    },
    showToast(titre, type) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: titre,
          icon: type === "success" ? "CheckSquareIcon" : "AlertCircleIcon",
          variant: type,
        },
      });
    },
    Class() {
      if (this.marques.length || this.couleurs.length) {
        return "";
      } else if (!this.marques.length && !this.couleurs.length) {
        return "border-right";
      }
    },
    getMarques() {
      this.$http
        .get("/marques")
        .then((res) => {
          if (res.status) {
            this.hiddenLoaderMark = true;
          }

          this.marques = res.data.data.reverse();
          if (this.marques.length) {
            this.hiddenMark = false;
            this.hiddenEmptyMark = true;
          } else {
            this.hiddenMark = true;
            this.hiddenEmptyMark = false;
          }
        })
        .catch((e) => {
          console.log("erreur de récupération:" + e.response.data.message);
        });
    },
    addMarque() {
      this.$refs.addMarqueForm.validate().then((success) => {
        if (success) {
          this.etatButtonMark = false;
          // this.isOperating = false;
          this.$http
            .post("/marques", this.defaultMarqueForm)
            .then((result) => {
              if (result.data.success) {
                this.etatButtonMark = true;
                this.resetInputMarque();
                this.defaultMarqueForm = JSON.parse(
                  JSON.stringify({ type: Object, default: () => {} })
                );
                this.addMark = false;
                this.hiddenMark = false;
                this.$refs.addMarqueForm.reset();
                this.showToast("Marque ajouté avec succès", "success");
                if (this.marques.length) {
                  this.$refs.collapseMark.updateVisible(false);
                }
                this.getMarques();
                // this.resetForm()
              } else {
                this.etatButtonMark = true;
                this.showToast(result.data.message, "danger");
              }
            })
            .catch((e) => {
              this.etatButtonMark = true;
              var err = e.response.data.errors;
              // this.isOperating = false;

              if (err.name) {
                this.errorsNameMarque = err.name[0];
              }
            });
        }
      });
    },
    checkFormValidityMarque() {
      const valid = this.$refs.editMarqueForm.checkValidity();
      this.nameState = valid;
      return valid;
    },
    handleSubmitMarque() {
      if (!this.checkFormValidityMarque()) {
        return;
      }
      this.etatButtonEditMarque = false;
      this.$http
        .put("/marques/" + this.editedMarqueForm.id, this.editedMarqueForm)
        .then((result) => {
          // 	this.isOperating = false;
          if (result.data.success) {
            this.etatButtonEditMarque = true;
            this.resetInputMarqueModal();
            this.editedMarqueForm = JSON.parse(
              JSON.stringify({ type: Object, default: () => {} })
            );
            this.modalEditMarque = false;
            this.showToast("Marque modifié avec succès", "success");
            this.$refs.addMarqueForm.reset();
            this.getMarques();
          } else {
            this.etatButtonEditMarque = true;
            this.showToast(result.data.message, "danger");
          }
        })
        .catch((e) => {
          this.etatButtonEditMarque = true;
          var err = e.response.data.errors;

          if (err.name) {
            this.errorsNameMarqueModal = err.name[0];
          }
        });
      // 	}
      // });

      // Hide the modal manually
      this.$nextTick(() => {
        this.$bvModal.hide("modal-prevent-closing");
      });
    },
    handleOkMarque(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();

      // Trigger submit handler
      this.handleSubmitMarque();
    },
    editMarque(item) {
      this.editedMarqueForm = Object.assign({}, item);
      this.modalEditMarque = true;
    },
    deleteMarque(item) {
      this.$bvModal
        .msgBoxConfirm(
          "Voulez-vous vraiment supprimer cet enregistrement de la liste des marques ?",
          {
            title: "Supprimer une marque",
            okVariant: "danger",
            cancelVariant: "outline-secondary",
            okTitle: "Confirmer",
            cancelTitle: "Annuler",
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then((value) => {
          if (value === true) {
            this.$http
              .delete("/marques/" + item.id)
              .then((result) => {
                if (result.data.success) {
                  this.showToast("Supression effectuée avec succès", "success");
                  this.getMarques();
                } else {
                  this.showToast(result.data.message, "danger");
                }
              })
              .catch((err) => {
                this.showToast(err.response, "error");
              });
          }
        });
    },
    ExportFomatExcelMarque() {
      let { json2excel, excel2json } = require("js2excel");
      // let headers = this.headersMarques
      let data = [];
      let obj = {};

      this.ExcelMarques.forEach((element) => {
        obj = {
          NOM: element.name,
        };

        data.push(obj);
      });

      if (data) {
        try {
          json2excel({
            // headers,
            data,
            name: "FlashCar - Liste des Marques",
            formateDate: "yyyy/mm/dd",
          });
        } catch (e) {
          console.log(e);
        }
      }
    },


    ExportAllToFomatExcelMarque() {
      let { json2excel, excel2json } = require("js2excel");
      // let headers = this.headersMarques
      let data = [];
      let obj = {};

      this.marques.forEach((element) => {
        obj = {
          NOM: element.name,
        };

        data.push(obj);
      });

      if (data) {
        try {
          json2excel({
            // headers,
            data,
            name: "FlashCar - Liste des Marques",
            formateDate: "yyyy/mm/dd",
          });
        } catch (e) {
          console.log(e);
        }
      }
    },
    // Marque end

    // Couleur start
    resetCouleurForm() {
      this.defaultCouleurForm.name = "";
    },
    resetCouleurFormModal() {
      this.editedCouleurForm.name = "";
      this.nameState = null;
      this.resetInputCouleurModal();
    },
    resetInputCouleur() {
      this.resetInputNameCouleur();
    },
    resetInputNameCouleur() {
      if (this.errorsNameCouleur) {
        this.errorsNameCouleur = null;
      }
    },
    resetInputCouleurModal() {
      this.resetInputNameCouleurModal();
    },
    resetInputNameCouleurModal() {
      if (this.errorsNameCouleurModal) {
        this.errorsNameCouleurModal = null;
      }
    },
    showToast(titre, type) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: titre,
          icon: type === "success" ? "CheckSquareIcon" : "AlertCircleIcon",
          variant: type,
        },
      });
    },
    getCouleurs() {
      this.$http
        .get("/couleurs")
        .then((res) => {
          if (res.status) {
            this.hiddenLoaderColor = true;
          }

          this.couleurs = res.data.data.reverse();

          if (this.couleurs.length) {
            this.hiddenColor = false;
            this.hiddenEmptyColor = true;
          } else {
            this.hiddenColor = true;
            this.hiddenEmptyColor = false;
          }
        })
        .catch((e) => {
          console.log("erreur de récupération:" + e.response.data.message);
        });
    },
    addCouleur() {
      this.$refs.addCouleurForm.validate().then((success) => {
        if (success) {
          this.etatButtonColor = false;
          // this.isOperating = false;
          this.$http
            .post("/couleurs", this.defaultCouleurForm)
            .then((result) => {
              // 	this.isOperating = false;
              if (result.data.success) {
                this.etatButtonColor = true;
                this.resetInputCouleur();
                this.defaultCouleurForm = JSON.parse(
                  JSON.stringify({ type: Object, default: () => {} })
                );
                this.addColor = false;
                this.hiddenColor = false;
                this.showToast("Couleur ajouté avec succès", "success");
                this.$refs.addCouleurForm.reset();
                if (this.couleurs.length) {
                  this.$refs.collapseColor.updateVisible(false);
                }
                this.getCouleurs();
                // this.resetForm()
              } else {
                this.etatButtonColor = true;
                this.showToast(result.data.message, "danger");
              }
            })
            .catch((e) => {
              this.etatButtonColor = true;
              console.log(e.response.data.errors);
              var err = e.response.data.errors;
              // this.isOperating = false;

              if (err.name) {
                this.errorsNameCouleur = err.name[0];
              }
            });
        }
      });
    },
    checkFormValidityCouleur() {
      const valid = this.$refs.editCouleurForm.checkValidity();
      this.nameState = valid;
      return valid;
    },
    handleSubmitCouleur() {
      if (!this.checkFormValidityCouleur()) {
        return;
      }
      this.etatButtonEditCouleur = false;
      this.$http
        .put("/couleurs/" + this.editedCouleurForm.id, this.editedCouleurForm)
        .then((result) => {
          // 	this.isOperating = false;
          if (result.data.success) {
            this.etatButtonEditCouleur = true;
            this.resetInputCouleurModal();
            this.editedCouleurForm = JSON.parse(
              JSON.stringify({ type: Object, default: () => {} })
            );
            this.modalEditCouleur = false;
            this.showToast("Couleur modifié avec succès", "success");
            this.$refs.addCouleurForm.reset();
            this.getCouleurs();
          } else {
            this.etatButtonEditCouleur = true;
            this.showToast(result.data.message, "danger");
          }
        })
        .catch((e) => {
          this.etatButtonEditCouleur = true;
          // this.isOperatingCouleur = false;
          var err = e.response.data.errors;
          // this.isOperatingCouleur = false;

          if (err.name) {
            this.errorsNameCouleurModal = err.name[0];
          }
        });
      // 	}
      // });

      // Hide the modal manually
      this.$nextTick(() => {
        this.$bvModal.hide("modal-prevent-closing");
      });
    },
    handleOkCouleur(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();

      // Trigger submit handler
      this.handleSubmitCouleur();
    },
    editCouleur(item) {
      this.editedCouleurForm = Object.assign({}, item);
      this.modalEditCouleur = true;
    },
    deleteCouleur(item) {
      // Message de suppression
      this.$bvModal
        .msgBoxConfirm(
          "Voulez-vous vraiment supprimer cet enregistrement de la liste des couleurs ?",
          {
            title: "Supprimer une couleur",
            okVariant: "danger",
            cancelVariant: "outline-secondary",
            okTitle: "Confirmer",
            cancelTitle: "Annuler",
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then((value) => {
          if (value === true) {
            this.$http
              .delete("/couleurs/" + item.id)
              .then((result) => {
                if (result.data.success) {
                  this.showToast("Supression effectuée avec succès", "success");
                  this.getCouleurs();
                } else {
                  this.showToast(result.data.message, "danger");
                }
              })
              .catch((err) => {
                this.showToast(err.response, "error");
              });
          }
        });
    },
    ExportFomatExcelCouleur() {
      let { json2excel, excel2json } = require("js2excel");
      // let headers = this.headersCouleurs
      let data = [];
      let obj = {};

      this.ExcelCouleurs.forEach((element) => {
        obj = {
          NOM: element.name,
        };

        data.push(obj);
      });

      if (data) {
        try {
          json2excel({
            // headers,
            data,
            name: "FlashCar - Liste des Couleurs",
            formateDate: "yyyy/mm/dd",
          });
        } catch (e) {
          console.log(e);
        }
      }
    },

    ExportAllToFomatExcelCouleur() {
      let { json2excel, excel2json } = require("js2excel");
      // let headers = this.headersCouleurs
      let data = [];
      let obj = {};

      this.couleurs.forEach((element) => {
        obj = {
          NOM: element.name,
        };

        data.push(obj);
      });

      if (data) {
        try {
          json2excel({
            // headers,
            data,
            name: "FlashCar - Liste des Couleurs",
            formateDate: "yyyy/mm/dd",
          });
        } catch (e) {
          console.log(e);
        }
      }
    },
    // Couleur end
  },
};
</script>

<style>
	.loader {
		position: absolute;
		width: 100%;
		height: 100%;
		background-color: #f8f8f8;
		padding-top: 35%;
		z-index: 1000;
	}

	.container-search-btn {
		display: flex !important;
	}

	.flex-stack {
		justify-content: space-between;
		align-items: center;
	}
	
	@media screen and (max-width: 435px) {
		.container-search-btn {
			display: flex !important;
		}

		.container-vehicule {
			display: block !important;
		}

		.container-btn {
			margin-left: 0px !important;
		}

		.container-search {
			width: 50%;
		}
	}

	@media screen and (max-width: 327px) {
		.container-search {
			width: 100%;
			margin-bottom: 10px;
		}

		.container-search-btn {
			display: block !important;
		}
	}
</style>